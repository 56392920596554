<template>
  <div class="extension-list" :class="themeColorClass">
    <UiHeader
      v-if="header"
      class="extension-list__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <div v-if="itemList.length" class="extension-list__list">
      <template v-for="item in itemList" :key="item.uid">
        <component
          :is="isLinkValid(item.link) ? 't3-link' : 'div'"
          :to="item.link"
          class="extension-list__item"
        >
          <T3HtmlParser :content="item.name" />

          <SvgIconLinkArrow v-if="isLinkValid(item.link)" />
        </component>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UiCeProductBoxesProps } from '~/types'
import SvgIconLinkArrow from '~ui/assets/icons/icon-link-arrow.svg'
import { UiHeader } from '~ui/components'
import useTheming from '~ui/composables/useTheming'
import { isLinkValid } from '~ui/helpers/linkUtils'

const props = defineProps<UiCeProductBoxesProps>()

const themeColorClass = useTheming(props.themeColor)
</script>

<style lang="scss">
.extension-list {
  display: flex;
  flex-direction: column;
  gap: rem(12px);

  @include media-query(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    gap: rem(48px);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem(24px);

    & a {
      @include themed-hover;
    }
  }

  &__item {
    border-radius: em(8px);
    border: 1px solid color(silver);
    padding: em(12px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: color(white);

    & svg {
      height: 24px;
      aspect-ratio: 1;

      & path {
        fill: hsl(0 0% 0% / 54%);
        transition: fill 0.4s ease;
      }
    }

    &:is(:hover, :focus, :active) svg path {
      fill: var(--theme__theme-color);
    }
  }
}
</style>
